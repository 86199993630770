import { type ReactNode, useEffect } from 'react';
import TagManager from 'react-gtm-module';
import { useSelector } from 'utils/hooks/redux';
import { MODE, APP_VERSION } from 'helper/environment';
import { selectLoadingLocations } from 'state/locationsSlice';
import Loading from 'components/Loading';

import AppContainerWrapper, { LoadingWrapper } from './styles';
import { useTranslation } from 'react-i18next';
import { useApi } from 'utils/hooks/useApi';
import { useAuth0, useProfileV2 } from 'core';
import { checkIfInternalUser } from 'core/src/lib/utils/isInternalUser';

type AppContainerProps = {
  children: ReactNode;
};

export function AppContainer({ children }: AppContainerProps) {
  const { profile } = useProfileV2();
  const { isAuthenticated, isLoading } = useAuth0();
  const loadingLocations = useSelector(selectLoadingLocations);
  const { t, ready } = useTranslation();
  const { fetchLocations } = useApi();

  const loadData = () => {
    void fetchLocations();
  };

  useEffect(() => {
    if (profile) {
      loadData();
    }
  }, [profile]);

  useEffect(() => {
    if (isAuthenticated && profile && MODE === 'production') {
      const { email, fullName, phone } = profile;
      const tagManagerArgs = {
        dataLayer: {
          userId: email,
          name: fullName,
          email: email,
          phoneNumber: phone,
          internal: checkIfInternalUser(email),
          appVersion: APP_VERSION,
        },
      };

      TagManager.dataLayer(tagManagerArgs);
    }
  }, [isAuthenticated, profile]);

  return (
    <AppContainerWrapper>
      {loadingLocations || isLoading ? (
        <LoadingWrapper>
          <Loading text={ready ? t('loading.data') : 'Loading data'} />
        </LoadingWrapper>
      ) : (
        children
      )}
    </AppContainerWrapper>
  );
}

export default AppContainer;
