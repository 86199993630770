import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { colors, spacing, typography } from '../../../themes/stena-recycling';
import { type StyledButtonProps } from '../../../types';
import { Spinner } from '../../Feedback/Spinner';
import { animationTransition } from '../../helpers/styleHelpers';
import { Link } from 'react-router-dom';

const variantTypography = {
  primary: typography.other.button.label,
  secondary: typography.other.button.label,
  tertiary: typography.other.button.label,
  'text-primary': typography.other.button.secondaryLabel,
  'text-secondary': typography.other.button.secondaryLabel,
};

export const StyledButton = styled.button<StyledButtonProps>`
  font-family: ${typography.fontFamily};
  display: flex;
  border: none;
  user-select: none;
  transition: background-color ${animationTransition};
  height: ${spacing.mediumHigh};
  justify-content: center;
  align-items: center;
  cursor: pointer;
  ${({ fullWidth }) => fullWidth && 'width: 100%'};

  ${({ variant }) => (variant && variantTypography[variant]) ?? null}

  ${({ variant, severity, isLoading, hasIcon }) =>
    getButtonVariant(variant, severity, isLoading, hasIcon)}

  ${({ hasIcon, isLoading }) =>
    hasIcon &&
    isLoading &&
    'padding-left: 16px; padding-right: 20px;'} // 24px is the width of the spinner, divide this and add to default padding
`;

export const Label = styled.span<
  {
    isLoading?: boolean;
    icon: boolean;
    iconPlacement?: 'before' | 'after';
  } & Pick<StyledButtonProps, 'variant' | 'severity'>
>`
  opacity: ${({ isLoading }) => (isLoading ? 0 : 1)};
  color: inherit;

  ${({ icon, iconPlacement, variant }) => {
    if (icon && iconPlacement !== 'after') {
      return css`
        margin-left: ${variant && (variant === 'text-primary' || variant === 'text-secondary')
          ? spacing.tiny
          : spacing.xxsmall};
      `;
    }

    if (icon && iconPlacement === 'after') {
      return css`
        margin-right: ${variant && (variant === 'text-primary' || variant === 'text-secondary')
          ? spacing.tiny
          : spacing.xxsmall};
      `;
    }
  }}
`;

// Loader
export const ButtonSpinner = styled(Spinner)`
  position: absolute;
`;

// Icons
const iconStyles = css`
  width: ${spacing.small};
  height: ${spacing.small};
  box-sizing: border-box;
`;

export const IconLeft = styled.div`
  color: inherit;
  ${iconStyles}
`;

export const IconRight = styled.div`
  color: inherit;
  ${iconStyles}
`;

const getButtonColor = (
  severity?: 'danger' | 'warning' | 'info' | 'success',
  type?: 'shade1' | 'shade2',
) => {
  switch (severity) {
    case 'success':
      if (type === 'shade1') {
        return colors.alerts.successShade1;
      }
      if (type === 'shade2') {
        return colors.alerts.successShade2;
      }

      return colors.alerts.success;
    case 'warning':
      if (type === 'shade1') {
        return colors.alerts.warningShade1;
      }
      if (type === 'shade2') {
        return colors.alerts.warningShade2;
      }

      return colors.alerts.warning;
    case 'danger':
      if (type === 'shade1') {
        return colors.alerts.errorShade1;
      }
      if (type === 'shade2') {
        return colors.alerts.errorShade2;
      }

      return colors.alerts.error;
    default:
      if (type === 'shade1') {
        return colors.primary.blueShade1;
      }
      if (type === 'shade2') {
        return colors.primary.blueShade2;
      }

      return colors.primary.blue;
  }
};

const getButtonVariant = (
  variant?: 'primary' | 'secondary' | 'tertiary' | 'text-primary' | 'text-secondary',
  severity?: 'danger' | 'warning' | 'info' | 'success',
  isLoading?: boolean,
  hasIcon?: boolean,
) => {
  switch (variant) {
    case 'secondary':
      return css`
        height: ${spacing.mediumHigh};
        padding: ${spacing.xmsmall} ${spacing.mediumLow};
        background-color: ${colors.base.white};
        color: ${getButtonColor(severity)};
        border: 1px solid ${getButtonColor(severity)};
        box-sizing: border-box;

        &:disabled {
          color: ${isLoading ? getButtonColor(severity) : colors.monochrome.grey40};
          border: 1px solid ${colors.monochrome.grey20};
          pointer-events: none;
        }

        &:hover:not(:disabled) {
          border: 1px solid ${getButtonColor(severity, 'shade1')};
          color: ${getButtonColor(severity, 'shade1')};
        }

        &:active:not(:disabled) {
          border: 1px solid ${getButtonColor(severity, 'shade2')};
          color: ${getButtonColor(severity, 'shade2')};
        }
      `;
    case 'tertiary':
      return css`
        height: auto;
        padding: 0;
        background-color: transparent;
        color: ${getButtonColor(severity)};

        &:disabled {
          color: ${isLoading ? getButtonColor(severity) : colors.monochrome.grey40};
          pointer-events: none;
        }

        &:hover:not(:disabled) {
          color: ${getButtonColor(severity, 'shade1')};
        }

        &:active:not(:disabled) {
          color: ${getButtonColor(severity, 'shade2')};
        }
      `;

    case 'text-primary':
      return css`
        height: auto;
        padding: ${spacing.tiny} ${spacing.xxsmall} ${spacing.tiny}
          ${hasIcon ? spacing.tiny : spacing.xxsmall};
        background-color: transparent;
        color: ${getButtonColor(severity)};
        border: 1px solid ${getButtonColor(severity)};
        border-radius: 4px;
        box-sizing: border-box;

        &:disabled {
          color: ${isLoading ? getButtonColor(severity) : colors.monochrome.grey40};
          border: 1px solid ${colors.monochrome.grey20};
          pointer-events: none;
        }

        &:hover:not(:disabled) {
          border: 1px solid ${getButtonColor(severity, 'shade1')};
          color: ${getButtonColor(severity, 'shade1')};
        }

        &:active:not(:disabled) {
          border: 1px solid ${getButtonColor(severity, 'shade2')};
          color: ${getButtonColor(severity, 'shade2')};
        }
      `;

    case 'text-secondary':
      return css`
        height: auto;
        padding: 0;
        background-color: transparent;
        color: ${getButtonColor(severity)};
        border-radius: 4px;
        box-sizing: border-box;

        &:disabled {
          color: ${isLoading ? getButtonColor(severity) : colors.monochrome.grey40};
          pointer-events: none;
        }

        &:hover:not(:disabled) {
          color: ${getButtonColor(severity, 'shade1')};
        }

        &:active:not(:disabled) {
          color: ${getButtonColor(severity, 'shade2')};
        }
      `;

    case 'primary':
    default:
      return css`
        height: ${spacing.mediumHigh};
        padding: ${spacing.xmsmall} ${spacing.mediumLow};
        background-color: ${getButtonColor(severity)};
        color: ${colors.base.white};

        &:disabled {
          background-color: ${colors.monochrome.grey20};
          color: ${isLoading ? getButtonColor(severity) : colors.monochrome.grey40};
          pointer-events: none;
        }

        &:hover:not(:disabled) {
          background-color: ${getButtonColor(severity, 'shade1')};
        }

        &:active:not(:disabled) {
          background-color: ${getButtonColor(severity, 'shade2')};
        }
      `;
  }
};

export const StyledLink = styled(Link, {
  shouldForwardProp: (prop) => prop !== 'fullWidth' && prop !== 'isLoading' && prop !== 'hasIcon',
})<StyledButtonProps>`
  font-family: ${typography.fontFamily};
  display: flex;
  border: none;
  user-select: none;
  transition: background-color ${animationTransition};
  height: ${spacing.mediumHigh};
  justify-content: center;
  align-items: center;
  cursor: pointer;
  text-decoration: none;
  ${({ fullWidth }) => fullWidth && 'width: 100%'};

  ${({ variant }) => (variant && variantTypography[variant]) ?? null}

  ${({ variant, severity, isLoading, hasIcon }) =>
    getButtonVariant(variant, severity, isLoading, hasIcon)}

  ${({ hasIcon, isLoading }) =>
    hasIcon &&
    isLoading &&
    'padding-left: 16px; padding-right: 20px;'} // 24px is the width of the spinner, divide this and add to default padding
`;
