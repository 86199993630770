import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet } from 'react-router-dom';
import { selectOrders, selectLoadingOrders, selectLoadingError } from 'state/ordersSlice';
import Page from 'components/Page';
import Loading from 'components/Loading';
import NoOrders from './NoOrders';
import { LoadingWrapper } from './styles';
import { useSelector } from 'utils/hooks/redux';
import ErrorMessage from 'components/ErrorMessage';
import { useApi } from 'utils/hooks/useApi';
import { useAuth0, useProfileV2 } from 'core';

const UserOrders = () => {
  const { t } = useTranslation();
  const loadingOrders = useSelector(selectLoadingOrders);
  const loadingError = useSelector(selectLoadingError);
  const orders = useSelector(selectOrders);
  const { fetchOrders } = useApi();
  const { isAuthenticated } = useAuth0();
  const { profile } = useProfileV2();

  useEffect(() => {
    if (isAuthenticated && profile) {
      fetchOrders('all');
    }
  }, [isAuthenticated]);

  const haveOrders = () => (orders ? Object.values(orders).flat().length > 0 : false);
  const firstTimeLoading = !haveOrders();

  // Loading State
  if (loadingOrders.length > 0 && firstTimeLoading) {
    return (
      <Page>
        <LoadingWrapper>
          <Loading text={t('userOrders.loading')} />
        </LoadingWrapper>
      </Page>
    );
  }

  // Error State
  if (loadingError) {
    return (
      <Page>
        <ErrorMessage
          subtitle={t('errorMessage.loadOrders')}
          buttonTitle={t('errorMessage.button.retry')}
          buttonURL="/orders"
          coverMenu={false}
        />
      </Page>
    );
  }

  // No orders State
  if (!haveOrders()) {
    return <NoOrders />;
  }

  // Default State
  return <Outlet />;
};

export default UserOrders;
