import { createContext, type ReactNode, useEffect, useMemo, useState } from 'react';
import { type PartnerResItem, type PartnerTreeNodeV2 } from '@stenarecycling/customer-portal-types';
import { useAuth0 } from '@auth0/auth0-react';
import { useTransactionId } from '../hooks/useTransactionId';
import { type BasicProfile, useProfileV2 } from '../Profile';
import { fetchBusinessPartners, getDwkeysFromPartnerTrees } from './utils';

// Create a new context for the partner trees, all DW keys, and loading state
export const LocationsContext = createContext<{
  partnerTrees: PartnerTreeNodeV2[];
  allDwKeys: string[];
  loading: boolean;
  error: boolean;
  locationsById: Record<string, PartnerResItem | undefined>;
} | null>(null);

export const LocationsProvider = ({ children }: { children: ReactNode }) => {
  const { basicProfile } = useProfileV2();
  const { getAccessTokenSilently, isAuthenticated } = useAuth0();
  const [partnerTrees, setPartnerTrees] = useState<PartnerTreeNodeV2[]>([]);
  const [locationsById, setLocationsById] = useState<Record<string, PartnerResItem | undefined>>(
    {},
  );
  const [missingPartnersCount, setMissingPartnersCount] = useState<number>(0);
  const [allDwKeys, setAllDwKeys] = useState<string[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState(false);
  const transactionId = useTransactionId();

  useEffect(() => {
    (async () => {
      if (
        !isAuthenticated ||
        !basicProfile ||
        profileMatchPartnerTrees(partnerTrees, basicProfile, missingPartnersCount)
      )
        return;

      try {
        setLoading(true);
        const accessToken = await getAccessTokenSilently();

        const dwKeys = basicProfile.rootPartnerIds;

        const bps = await fetchBusinessPartners({
          dwKeys,
          transactionId,
          accessToken,
        });

        const partnerTrees = bps.partners.toSorted(sortPartnersConfidentialLast);

        const allDwKeys = getDwkeysFromPartnerTrees(partnerTrees);

        setMissingPartnersCount(bps.missingPartnersInApiCount);
        setPartnerTrees(partnerTrees);
        setAllDwKeys(allDwKeys);
        setLocationsById(getLocationsById(partnerTrees));
      } catch (e) {
        console.error(e);
        setError(true);
        setAllDwKeys([]);
        setPartnerTrees([]);
        setMissingPartnersCount(0);
      } finally {
        setLoading(false);
      }
    })().catch((error: unknown) => {
      console.error(error);
    });
  }, [
    getAccessTokenSilently,
    transactionId,
    basicProfile,
    isAuthenticated,
    partnerTrees,
    missingPartnersCount,
  ]);

  const contextValue = useMemo(() => {
    return { partnerTrees, allDwKeys, loading, error, locationsById };
  }, [partnerTrees, allDwKeys, loading, error, locationsById]);

  return (
    // Provide the partner trees, all DW keys, and loading state through the context
    <LocationsContext.Provider value={contextValue}>{children}</LocationsContext.Provider>
  );
};

const profileMatchPartnerTrees = (
  partnerTrees: PartnerTreeNodeV2[],
  basicProfile: BasicProfile | undefined,
  missingPartnersInApi: number,
) => {
  const treeCount = partnerTrees.length + missingPartnersInApi;
  const profileCount = basicProfile?.rootPartnerIds.length ?? 0;

  return treeCount === profileCount;
};

const sortPartnersConfidentialLast = (a: PartnerTreeNodeV2, b: PartnerTreeNodeV2) => {
  if (a.item.name.startsWith('[Confidential]') && b.item.name.startsWith('[Confidential]')) {
    return a.item.name.localeCompare(b.item.name);
  }

  if (a.item.name.startsWith('[Confidential]')) {
    return 1;
  }
  if (b.item.name.startsWith('[Confidential]')) {
    return -1;
  }

  return a.item.name.localeCompare(b.item.name);
};

const getLocationsById = (partnerTrees: PartnerTreeNodeV2[]) => {
  const locationsById: Record<string, PartnerResItem | undefined> = {};

  const addRecursive = (node: PartnerTreeNodeV2) => {
    locationsById[node.item.dwKey] = node.item;
    if (node.children.length > 0) {
      for (const child of node.children) {
        addRecursive(child);
      }
    }
  };

  partnerTrees.forEach((partnerTree) => {
    addRecursive(partnerTree);
  });

  return locationsById;
};
